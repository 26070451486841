@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Roboto,sans-serif';
  src: url('./fonts/Atkinson/WOFF2/Atkinson-Hyperlegible-Regular-102a.woff2')
  format('woff2'),
  url('./fonts/Atkinson/WOFF/Atkinson-Hyperlegible-Regular-102.woff')
  format('woff'),
  url('./fonts/Atkinson/TTF/Atkinson-Hyperlegible-Regular-102.ttf')
  format('truetype'),
  url('./fonts/Atkinson/EOT/Atkinson-Hyperlegible-Regular-102.eot?#iefix')
  format('embedded-opentype'),
  url('./fonts/Atkinson/SVG/Atkinson-Hyperlegible-Regular-102.svg')
  format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
body {
  margin: 0;
  font-family: Roboto,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: .0071428571em;
  line-height: 33px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tw-text-xxs {
  font-size: 10px;
}

.tw-text-xxxs {
  font-size: 8px;
}

.tw-text-xs {
  font-size: 12px;
}

.tw-text-sm {
  font-size: 14px;
}

.tw-text-base {
  font-size: 16px;
}

.tw-text-lg {
  font-size: 18px;
}

.tw-text-xl {
  font-size: 20px;
}

.tw-text-2xl {
  font-size: 22px;
}

.tw-text-3xl {
  font-size: 24px;
}

.tw-text-4xl {
  font-size: 26px;
}

.tw-text-5xl {
  font-size: 28px;
}

.tw-text-6xl {
  font-size: 30px;
}

.tw-text-7xl {
  font-size: 32px;
}

.tw-text-8xl {
  font-size: 34px;
}

.tw-text-9xl {
  font-size: 36px;
}
